<template>
  <div class="container-fluid">
    <app-header
      :title="query"
      :action="loading ? null : {
        text: 'Create a task',
        href: '/new/' + type + '/' + id
      }"
    />
    <spinner v-if="loading" />
    <div
      v-else
      class="row"
    >
      <div
        v-for="(status, i) in statuses"
        :key="i"
        class="col d-flex flex-column align-items-stretch px-2"
      >
        <div
          class="status align-self-stretch p-1"
          :class="status.replace(/\s+/g, '-').toLowerCase()"
        >
          <h5>
            <span class="badge">{{ status }} <strong>{{ filteredItemsByStatus(status).length }}</strong></span>
          </h5>
          <kanban-item
            v-for="item in filteredItemsByStatus(status)"
            :key="item.id"
            :item="item"
            @open="openModal"
          />
        </div>
      </div>
    </div>
  </div>
  <app-modal
    :show="showModal"
    :title="modalTitle"
    @close="closeModal"
  >
    <spinner v-if="blocks.length === 0" />
    <div v-else>
      <notion-block
        v-for="block in blocks"
        :key="block.id"
        :block="block"
      />
    </div>
    <!--<pre>{{ blocks }}</pre>-->
    <template #footer>
      <button
        class="btn btn-success"
        @click="approveNotionBoardItem"
      >
        Approve Task
      </button>
    </template>
  </app-modal>
</template>

<script>
import { mapMutations } from 'vuex'
import AppHeader from '@/components/organisms/AppHeader'
import AppModal from '@/components/organisms/AppModal'
import Spinner from '@/components/atoms/Spinner'
import KanbanItem from '@/components/molecules/KanbanItem'
import NotionBlock from '@/components/molecules/NotionBlock'
export default {
  name: 'Kanban',
  components: {
    AppHeader,
    AppModal,
    Spinner,
    KanbanItem,
    NotionBlock
  },
  props: {
    type: {
      type: String,
      required: true
    },
    query: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      id: null,
      showModal: false,
      modalTitle: null,
      apiBaseURL: '/.netlify/functions',
      loading: false,
      success: false,
      items: [],
      activeItem: null,
      blocks: [],
      statuses: [
        'Backlog',
        'Planning',
        'Ready',
        'In Progress',
        'Testing',
        'Client Approval',
        'Completed'
      ]
    }
  },
  mounted () {
    this.getNotionBoard()
  },
  methods: {
    ...mapMutations({
      setModalOpen: 'setModalOpen'
    }),
    async getNotionBoard () {
      this.loading = true
      const response = await fetch(`${this.apiBaseURL}/get-notion-board?type=${this.type}&query=${this.query}`)
      if (response.ok) {
        const data = await response.json()
        this.id = data.id
        this.items = data.results
        this.loading = false
      }
    },
    async getNotionBoardItem (id) {
      this.activeItem = id
      const response = await fetch(`${this.apiBaseURL}/get-notion-board-item?id=${id}`)
      if (response.ok) {
        const data = await response.json()
        this.blocks = data.results
      }
    },
    async approveNotionBoardItem () {
      confirm('Are you sure you wish to mark as completed?')
      const response = await fetch(`${this.apiBaseURL}/change-item-status?id=${this.activeItem}`)
      if (response.ok) {
        this.closeModal()
        await this.getNotionBoard()
      }
    },
    filteredItemsByStatus (status) {
      return this.items.filter(item => item.properties.Status.select.name === status)
    },
    openModal (item) {
      this.setModalOpen(true)
      this.getNotionBoardItem(item.id)
      this.showModal = true
      this.modalTitle = item.properties.Task.title[0].plain_text
      console.log(item.id)
    },
    closeModal () {
      this.setModalOpen(false)
      this.blocks = []
      this.showModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  .badge {
    font-weight: normal;
  }
  &.backlog {
    background: rgba(247, 247, 245, 0.7);

    .badge {
      color: rgb(50, 48, 44);
      background: rgb(227, 226, 224);
    }
  }
  &.planning {
    background: rgba(253, 245, 243, 0.7);

    .badge {
      color: rgb(93, 23, 21);
      background: rgb(255, 226, 221);
    }
  }
  &.ready {
    background: rgba(252, 245, 242, 0.7);

    .badge {
      color: rgb(73, 41, 14);
      background: rgb(250, 222, 201);
    }
  }
  &.in-progress {
    background: rgba(250, 247, 237, 0.7);

    .badge {
      color: rgb(64, 44, 27);
      background: rgb(253, 236, 200);
    }
  }
  &.testing {
    background: rgba(249, 246, 252, 0.7);

    .badge {
      color: rgb(65, 36, 84);
      background: rgb(232, 222, 238);
    }
  }
  &.client-approval {
    background-color: rgba(241, 248, 251, 0.7);

    .badge {
      color: rgb(24, 51, 71);
      background: rgb(211, 229, 239);
    }
  }
  &.invoiced {
    background-color: rgba(251, 245, 251, 0.7);

    .badge {
      color: rgb(76, 35, 55);
      background: rgb(245, 224, 233);
    }
  }
  &.completed {
    background-color: rgba(244, 248, 243, 0.7);

    .badge {
      color: rgb(28, 56, 41);
      background: rgb(219, 237, 219);
    }
  }
}
</style>
