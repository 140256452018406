<template>
  <!-- Divider -->
  <hr v-if="block.type === 'divider'">

  <!-- To Do -->
  <div
    v-else-if="block.type === 'to_do'"
    class="mb-3"
  >
    <p
      :class="{
        'text-decoration-line-through': block.to_do.checked,
        'text-muted': block.to_do.checked
      }"
      class="form-check-label"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="#6c757d"
      >
        <path
          v-if="block.to_do.checked"
          d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h14q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21zm5.6-4.8l7.05-7.05-1.4-1.4-5.65 5.65-2.85-2.85-1.4 1.4z"
        />
        <path
          v-else
          d="M5 21q-.8 0-1.4-.6Q3 19.8 3 19V5q0-.8.6-1.4Q4.2 3 5 3h14q.8 0 1.4.6.6.6.6 1.4v14q0 .8-.6 1.4-.6.6-1.4.6Zm0-2h14V5H5v14Z"
        />
      </svg>
      <notion-text
        v-for="(text, i) in block[block.type].text"
        :key="i"
        :text="text"
      />
    </p>
  </div>

  <!-- File -->
  <p v-else-if="block.type === 'file' && block.file.type === 'external'">
    <a
      :href="block.file.external.url"
      target="_blank"
    >{{ getFileName(block.file.external.url) }}</a>
  </p>

  <component
    :is="elementMap[block.type]"
    v-else
  >
    <notion-text
      v-for="(text, i) in block[block.type].text"
      :key="i"
      :text="text"
    />
  </component>
</template>

<script>
import NotionText from '@/components/atoms/NotionText'
export default {
  name: 'NotionBlock',
  components: {
    NotionText
  },
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      elementMap: {
        heading_1: 'h1',
        heading_2: 'h2',
        heading_3: 'h3',
        paragraph: 'p'
      }
    }
  },
  mounted () {
    console.log(this.block)
  },
  methods: {
    nl2br (str) {
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2')
    },
    getFileName (str) {
      return /.*\/(.*)/.exec(str)[1]
    }
  }
}
</script>
