<template>
  <header class="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
    <div class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
      <logo height="36" />
      <span class="ms-2 fs-5">&times;</span>
      <span class="ms-2 fs-4">{{ title }}<sup class="text-primary fw-bold">BETA</sup></span>
    </div>
    <div
      v-if="action"
      class="col-md-3 text-end"
    >
      <router-link
        :to="action.href"
        type="button"
        class="btn btn-primary"
      >
        {{ action.text }}
      </router-link>
    </div>
  </header>
</template>

<script>
import Logo from '@/components/atoms/Logo'

export default {
  name: 'AppHeader',
  components: {
    Logo
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'xFlo'
    },
    action: {
      type: Object,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
sup {
  top: -1em;
  font-size: 0.5em;
}
</style>
