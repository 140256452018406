<template>
  <div
    class="modal"
    :class="{ 'show': show }"
    tabindex="-1"
  >
    <div class="modal-dialog modal-lg shadow-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          />
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppModal',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: 'xFlo'
    }
  },
  emits: ['close']
}
</script>

<style scoped>
.modal.show {
  display: block;
}
</style>
