<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 105 29">
    <path fill="#888" d="M98.56 23.08a6.43 6.43 0 0 0 6.44-6.15c0-2.56-2.08-4.74-5.12-5.2l4.56-4.32v-2.2H93.5v2.56h7.04l-4.47 4.32 1.32 1.81h.9c2.17 0 3.74 1.42 3.74 3.08 0 1.51-1.5 3.55-3.48 3.55-1.97 0-3.18-.85-3.71-2.36l-2.5 1.33c1.03 2.4 3.34 3.58 6.21 3.58Z"/>
    <path fill="#231E19" d="M31.28 14.12c0 4.91 3.63 8.91 9.28 8.91a8.71 8.71 0 0 0 7.57-3.97l-3.72-2.08a4.47 4.47 0 0 1-3.88 2.13c-2.9 0-4.91-2.3-4.91-4.99 0-2.69 2-4.98 4.86-4.98 1.68 0 3.06.82 3.88 2.13l3.71-2.08a8.68 8.68 0 0 0-7.57-3.97c-5.59 0-9.22 4-9.22 8.9Zm22.25 8.96c1.81 0 3.03-.64 3.8-1.76v1.47h3.9V10.12h-4.15v6.72c0 1.6-.81 2.48-2.25 2.48-1.28 0-1.98-.77-1.98-2.13v-7.07h-4.18V18c0 3.04 1.87 5.1 4.86 5.1Zm17.23-.05a6.44 6.44 0 0 0 6.43-6.5c0-3.6-2.71-6.65-6.38-6.65-1.35 0-2.93.43-3.74 1.58V5.2h-3.93v17.6h3.93v-1.15c.9.86 2.12 1.4 3.69 1.4Zm-.62-3.6a2.88 2.88 0 0 1-2.99-2.9c0-1.8 1.3-3.05 2.99-3.05a3 3 0 0 1 2.95 3.04 2.95 2.95 0 0 1-2.95 2.91Zm14.42 3.6c2.3 0 4.31-.9 5.53-2.48l-2.44-2.03a3.87 3.87 0 0 1-3.01 1.23c-1.69 0-2.85-.85-3.2-2.24h9.33c.05-.32.08-.77.08-1.1a6.37 6.37 0 0 0-6.62-6.53 6.43 6.43 0 0 0-6.73 6.6c0 3.67 2.88 6.55 7.06 6.55Zm-3.07-7.9a2.78 2.78 0 0 1 2.8-2.13c1.35 0 2.57.86 2.7 2.14h-5.5Z"/>
    <path fill="#00B2FF" fill-opacity=".6" d="m9.19 0 4.59 4.76-4.6 4.75-4.59-4.76z"/>
    <path fill="#68F" d="M4.6 4.75 9.17 9.5 4.6 14.25.52 10.04a.78.78 0 0 1 0-1.07l4.07-4.22Z"/>
    <path fill="#6966FF" d="m9.18 9.5 4.6 4.75-4.6 4.76-4.59-4.76z"/>
    <path fill="#FF002E" fill-opacity=".6" d="m18.37 28.5-4.6-4.76 4.6-4.75 4.6 4.76z"/>
    <path fill="#FFC700" d="M22.96 23.75 18.37 19l4.6-4.75 4.07 4.21a.8.8 0 0 1 0 1.08l-4.08 4.21Z"/>
    <path fill="#FF5C00" d="m18.38 19-4.6-4.75 4.6-4.76 4.59 4.76z"/>
    <path fill="#FFA800" d="M22.96 14.25 18.37 9.5l4.6-4.75 4.07 4.21c.28.3.28.78 0 1.07l-4.08 4.22Z"/>
    <path fill="red" fill-opacity=".7" d="m18.37 9.5-4.6-4.75 4.6-4.76 4.6 4.76z"/>
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
