<template>
  <component
    :is="element"
    :href="text.href || null"
    :target="text.href ? '_blank' : null"
    :class="{
      'fw-bold': text.annotations.bold,
      'fst-italic': text.annotations.italic,
      'text-decoration-line-through': text.annotations.strikethrough,
      'text-decoration-underline': text.annotations.underline
    }"
    v-html="nl2br(text.text.content)"
  />
</template>

<script>
export default {
  name: 'NotionBlock',
  props: {
    text: {
      type: Object,
      required: true
    }
  },
  computed: {
    element () {
      if (this.text.plain_text === '') return 'br'
      if (this.text.href === '') return 'a'
      return 'span'
    }
  },
  methods: {
    nl2br (str) {
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2')
    }
  }
}
</script>
