<template>
  <div
    class="card mb-1 shadow-sm"
    :class="{
      'alert-danger': isExpedited
    }"
    @click="$emit('open', item)"
  >
    <div class="card-body m-1 p-2">
      <span
        v-if="isExpedited"
        class="badge bg-danger mb-2 text-uppercase"
      >Expedite</span>
      <h6 class="card-title mb-0">
        {{ title }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KanbanItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  emits: ['open'],
  computed: {
    title () {
      const fieldName = 'Task Name'
      try {
        return this.item.properties[fieldName].title[0].plain_text
      } catch (e) {
        return fieldName + ' field missing'
      }
    },
    isExpedited () {
      return this.item.properties.Expedite.checkbox
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  &:hover {
    cursor: pointer;
  }

  .card-title {
    font-size: .875rem;
  }
}
</style>
